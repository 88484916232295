<template>
  <div class="archive-question">
    <div class="archive-question title">
      <span
        class="d-block text-xl-h4 text-lg-h4 text-md-h4 text-sm-h5 text-h6 font-weight-bold black--text"
      >
        Arsip
      </span>
      <small class="text-subtitle-2">Kumpulan arsip materi dan soal</small>
    </div>
    <v-tabs
      v-model="tab"
      show-arrows
      fixed-tabs
    >
      <v-tab
        v-for="tabItem in tabs"
        :key="tabItem.title"
      >
        <span>  <v-icon>{{ tabItem.icon }}</v-icon> {{ tabItem.title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <archive-lesson></archive-lesson>
      </v-tab-item>
      <v-tab-item>
        <archive-question></archive-question>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// import { ref, watch, onMounted } from '@vue/composition-api'
import { mdiClipboardCheckOutline, mdiClipboardMinusOutline } from '@mdi/js'
import ArchiveQuestion from './archive-question/ArchiveQuestion.vue'
import ArchiveLesson from './archive-lesson/ArchiveLesson.vue'

export default {
  name: 'QuestionArchive',
  components: { ArchiveQuestion, ArchiveLesson },
  data() {
    return {
      tab: '',
      tabs: [
        // { title: 'Absensi Siswa' },
        {
          icon: mdiClipboardCheckOutline,
          title: 'Arsip Materi',
        },
        {
          icon: mdiClipboardMinusOutline,
          title: 'Arsip Soal',
        },
      ],
    }
  },

  methods: {},
}
</script>

<style scoped>
.v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
  border-bottom: 1px solid rgba(94, 86, 105, 0.14) !important;
  box-shadow: none !important;
}
.v-tabs-items::v-deep,
.v-tabs::v-deep .v-tabs-bar {
  background: transparent !important;
}
.scroll-menu {
  position: relative;
  z-index: 3;
  overflow-y: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  overflow-x: hidden;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.scroll-menu:hover {
  overflow-x: auto;
}

.demo-space-x::v-deep .v-chip {
  height: auto !important;
  padding: 10px 20px !important;
  border-radius: 24px !important;
}

.v-chip.v-chip--outlined.v-chip.v-chip::v-deep {
  background-color: #ffffff !important;
}

.btn-delete {
  color: #e30000 !important;
}

.text-question {
  color: rgba(94, 86, 105, 0.68) !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.shadow-none {
  box-shadow: none !important;
}
</style>
