<template>
  <div>
    <v-alert
      text
      color="info"
      class="mt-7 mb-3 pa-4"
    >
      <strong class="text-subtitle-2 font-weight-bold">KETERANGAN</strong>
      <strong class="d-block text-body-2 font-weight-bold mt-1 ">
        Arsip Materi:
        <span class="font-weight-regular">
          Kumpulan materi-materi yang telah dibuat dari materi di e-learning, dan hanya bisa dilihat oleh Guru
        </span>
      </strong>
    </v-alert>
    <div>
      <div class="py-8">
        <v-row class="justify-space-between">
          <v-col
            xl="4"
            lg="4"
            md="4"
            sm="12"
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="search"
                label="Cari Materi Soal"
                outlined
                dense
                class="bg-white"
                @change="searchHandler($event)"
              >
              </v-text-field>
            </div>
          </v-col>
          <v-col
            md="3"
            xl="3"
            lg="3"
            sm="12"
            cols="12"
            class="py-0"
          >
            <div class="d-xl-flex d-lg-flex d-md-flex">
              <span class="d-block mb-2 my-md-2 my-lg-2 my-xl-2 me-2">Urutkan:</span>
              <v-autocomplete
                v-model="sortValue"
                dense
                :items="sorts"
                outlined
                @change="filterBySort($event)"
              ></v-autocomplete>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-if="!isLoadingCard">
      <v-row class="mb-4">
        <v-col
          v-for="(archive,index) in archiveLessons"
          :key="index"
          sm="6"
          lg="4"
          md="4"
          cols="12"
          class="py-0"
        >
          <v-card
            class="mx-auto mb-6"
            max-widht="250"
            max-height="204"
          >
            <v-card-title class="d-flex pl-4 pr-2 py-2 justify-lg-space-between">
              <v-menu
                offset-y
                nudge-right=""
              >
                <template v-slot:activator="{on,attrs}">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="black"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="20">
                      {{ icons.mdiDotsVertical }}
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item :to="{name: 'archive-lesson-teacher-edit', params:{uuid: archive.uuid}}">
                    <v-list-item-title>
                      <div class="d-flex">
                        <div>
                          <v-img
                            width="16"
                            src="@/assets/icons/edit.svg"
                          ></v-img>
                        </div>
                        <small class="ml-2">Edit</small>
                      </div>
                    </v-list-item-title>
                  </v-list-item>

                  <a @click.prevent="confirmDestroy(archive.uuid)">
                    <v-list-item>
                      <v-list-item-title>
                        <div class="d-flex btn-delete">
                          <div>
                            <v-img
                              width="16"
                              src="@/assets/icons/trash-red.svg"
                            ></v-img>
                          </div>

                          <small class="ml-2">Hapus</small>
                        </div>
                      </v-list-item-title>
                    </v-list-item>
                  </a>
                </v-list>
              </v-menu>
            </v-card-title>
            <v-card-text class="text-center">
              <div class="d-flex justify-center align-center">
                <div>
                  <v-img
                    height="64"
                    width="64"
                    class="mb-4"
                    :src="archive.icon.icon"
                  ></v-img>
                </div>
              </div>
              <span
                class="black--text"
                max-width="10"
              > {{ archive.title }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row
        class="mb-5"
      >
        <v-col
          v-for="i in 12"
          :key="i"
          md="3"
        >
          <v-skeleton-loader
            height="140"
            type="card-avatar"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div class="d-block text-center">
      <div
        v-if="isEmpty"
        class="d-block"
      >
        <div class="d-flex justify-center">
          <div class="px-4 py-8 text-center">
            <v-img
              src="@/assets/images/vector/empty.svg"
              class="mx-8 my-auto"
            ></v-img>
            <p class="mt-3 mb-0">
              Belum ada materi soal
            </p>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="mt-6">
        <v-pagination
          v-model="page"
          :length="totalPages"
          total-visible="7"
          @change="paginationHandler"
        ></v-pagination>
      </div>
      <div class="mt-6">
        <v-btn
          color="primary"
          x-large
          :to="{name: 'archive-lesson-teacher-add'}"
        >
          Tambah Arsip Materi
        </v-btn>
      </div>
    </div>
    <ConfirmDialog
      :visible="confirmDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      header="Hapus Arsip Materi"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
      <template v-slot:body>
        Apakah anda yakin ingin menghapus arsip materi “{{ archiveLesson.title }}” ?
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import { mdiDotsVertical } from '@mdi/js'

export default {
  name: 'LessonArchive',
  components: { ConfirmDialog },

  data() {
    return {
      archiveLessons: [],
      archiveLesson: {},
      icons: {
        mdiDotsVertical,
      },
      isLoadingCard: true,
      isLoadingButton: false,
      confirmDialog: false,
      isEmpty: false,
      totalPages: 0,
      width: 420, // tanya
      page: 1,
      sorts: ['Terbaru', 'Terlama'],
      sortValue: 'Terbaru',
      service: 'archivelesson',
      sort: '',
      search: '',
      teacher: [],
      teacher_uuid: '',
      school: [],
      school_uuid: '',
    }
  },

  watch: {
    search: {
      handler() {
        this.isLoadingCard = true
        this.listArchiveLesson()
      },
    },
    page: {
      handler() {
        this.isLoadingCard = true
        this.listArchiveLesson()
      },
    },
    sort: {
      handler() {
        this.isLoadingCard = true
        this.listArchiveLesson()
      },
    },
    async mounted() {
      this.getSchoolUuid()
      await this.listArchiveLesson()
      this.isLoadingCard = false
    },
  },
  mounted() {
    this.getTeacher()
    this.listArchiveLesson()
  },
  methods: {
    getTeacher() {
      this.teacher = JSON.parse(localStorage.getItem('user')).user.details
      this.teacher.forEach(el => {
        if (el.teacher.uuid === undefined) {
          this.teacher_uuid = el.teacher.uuid

          return
        }
        if (el.teacher.uuid === null) {
          this.showSnackbar({
            text: "You don't have access to teacher",
            color: 'error',
          })

          return
        }
        this.teacher_uuid = el.teacher.uuid
      })

      return true
    },
    searchHandler(data) {
      this.search = data
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    filterBySort(data) {
      if (data === 'Terbaru') {
        this.sort = 'newest'
      } else {
        this.sort = 'oldest'
      }
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: "You don't have access to school",
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
        this.teacher_uuid = el.teacher.uuid
      })

      return true
    },
    async listArchiveLesson(params = {}) {
      this.isLoadingCard = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        search: this.search,
        per_page: 6,
        page: this.page,
        order_by: this.sort,
        teacher_uuid: this.teacher_uuid,
      }).then(
        ({ data }) => {
          this.archiveLessons = data.data
          this.isEmpty = false
          if (this.archiveLessons.length < 1) {
            this.isEmpty = true
          }
          this.totalPages = data.meta.last_page
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingCard = false
    },
    confirmDestroy(uuid) {
      this.archiveLesson.uuid = uuid
      this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.archiveLesson = data.data
      })
      this.confirmDialog = true
    },

    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.archiveLesson.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.confirmDialog = false
          this.isLoadingButton = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listArchiveLesson()
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style scoped>
.btn-delete {
  color: #e30000 !important;
}
</style>
